<template>
  <GameStage
    :stage="stage"
    :feedback="chosenAnswerFeedback"
    :badge="badge"
    :has-selected-answer="stage.hasChosenAnswer()"
    :questions-count="questionsCount"
    :current-question-number="currentQuestionNumber"
    :has-session-data="hasSessionAnswer"
    :is-loading="isLoading"
    @completed-show-badges="completedShowBadges"
    @completed="completed"
  >
    <div class="best-worst-question">
      <div class="instruction">
        <h3
          v-if="stage.instruction"
        >
          {{ stage.instruction }}
        </h3>
      </div>
      <ul>
        <li
          v-for="(answer, index) in stage.answers"
          :key="`answer-${index}`"
          class="answers"
        >
          <div
            class="best-worst-question__circle"
            :class="{ 'opacity-15': !answer.isChosenAsBestAnswer }"
            @click="chooseAsBest(answer)"
          >
            <Icon name="approve" />
          </div>
          <div
            class="best-worst-question__circle best-worst-question__circle--reject"
            :class="{ 'opacity-15': !answer.isChosenAsWorstAnswer }"
            @click="chooseAsWorst(answer)"
          >
            <Icon
              name="reject"
              :color="'#2D2D2D'"
            />
          </div>
          <AnswerBody :body="answer.body" />
        </li>
      </ul>
    </div>
  </GameStage>
</template>
<script>
import { mapMutations } from 'vuex';
import { SET_STAGE } from '@/store/flow/mutations.names';
import Icon from '@/components/Icon/Icon';
import GameStage from '@/components/Stage/GameStage';
import BestWorstGameQuestion from '@/domain/entities/flow/BestWorstGameQuestion';
import AnswerBody from '@/components/Stage/Partials/AnswerBody';

export default {
  name: 'BestWorstGameQuestion',
  components: {
    AnswerBody,
    GameStage,
    Icon,
  },
  props: {
    stage: {
      type: [BestWorstGameQuestion],
      required: true,
    },
    questionsCount: {
      type: Number,
      required: true,
    },
    currentQuestionNumber: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    chosenAnswerFeedback() {
      return this.stage.hasChosenAnswer() ? this.stage.chosenBestAnswer().feedback : null;
    },
    badge() {
      return this.stage.hasChosenAnswer() ? (this.stage.badge || this.stage.chosenBestAnswer().badge) : null;
    },
    hasSessionAnswer() {
      return this.stage.hasSessionAnswer();
    },
  },
  methods: {
    ...mapMutations('flow', { SET_STAGE }),
    chooseAsBest(answer) {
      this[SET_STAGE](this.stage.chooseBestAnswer(answer));
    },
    chooseAsWorst(answer) {
      this[SET_STAGE](this.stage.chooseWorstAnswer(answer));
    },
    completedShowBadges() {
      this.$emit('completed-show-badges');
    },
    completed() {
      this.$emit('completed');
    },
  },
};
</script>
<style lang="scss" scoped>
.best-worst-question {
  h3 {
    text-transform: uppercase;
    color: $black;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 3.4rem;
    @include media-min($lg) {
      text-transform: none;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    list-style: none;
    padding: 0 0 3rem;
    @include media-min($lg) {
      padding: 1rem 0 8rem;
    }
    li {
      display: flex;
      align-items: center;
      max-width: 800px;
      margin: 0;
      transition: all 0.2s cubic-bezier(0.22, 0.61, 0.35, 1);
      @media (hover: hover) {
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    min-width: 36px;
    width: 36px;
    height: 36px;
    padding: 0.8rem;
    margin-right: 1.4rem;
    cursor: pointer;
    transition: 0.5s opacity ease;
    svg {
      width: 16px;
      height: 16px;
    }
    &--reject {
      border-color: $black;
    }
  }
}
.opacity-15 {
  opacity: 0.15;
}
</style>
