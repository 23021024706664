<template>
  <p
    class="answer-body"
    v-html="sanitizeHtml(body, ['strong', 'ul', 'ol', 'li', 'br'])"
  />
</template>
<script>
import sanitize from '@/common/sanitize/sanitizeHtml';

export default {
  name: 'AnswerBody',
  props: {
    body: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...sanitize,
  },
};
</script>

<style lang="scss" scoped>
.answer-body {
  word-break: break-word;
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.35, 1);

  ::v-deep ul,
  ::v-deep ol {
    padding: 0;
    list-style-position: inside;
  }
}
</style>
